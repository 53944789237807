<template>
	<div id="dr_print" style="display: none">
		<h3>Delivery Receipt</h3>
		<hr>
		<h4>DR Information</h4>
		<table>
			<tr>
				<td width="400">
					Booking No: <strong>{{dataParams.booking.booking_no}}</strong>
				</td>
				<td width="400">
					Delivery Date: <strong>{{dataParams.delivery_date ? moment(new Date (dataParams.delivery_date*1000)).format('MMMM DD, YYYY') : "N/A"}}</strong>
				</td>
			
				
				
			</tr>
			<tr>
				<td width="400">
					Customer: <strong>{{dataParams.customer ? dataParams.customer.customer_name : "N/A"}}</strong>
				</td>
				<td width="400">
					DR No: <strong>{{dataParams.dr_id}}</strong>
				</td>
				</tr>
			<tr>
				<td width="400">
					Department: <strong>{{dataParams.department ? dataParams.department : "N/A"}}</strong>
				</td>
				<td width="400">
					Contact Person: <strong>{{dataParams.contact_person ? dataParams.contact_person : "N/A"}}</strong>
				</td> 
				
			</tr>
			<tr>
				<td width="400">
					Address: <strong>{{dataParams.address ? dataParams.address : "N/A"}}</strong>
				</td>
				
				 
			</tr>
		</table>
		
		<hr/> 
		<h4>DR Items</h4>
		<table border="1">
			<tr style="-webkit-print-color-adjust:exact; background-color: black">
				<td width="225" style="color: white">
					Asset Type
				</td>
				<td width="225" style="color: white">
					Asset No
				</td>
				<td width="225" style="color: white">
					Qty
				</td>
				<td width="225" style="color: white">
					Unit
				</td>
				<td width="225" style="color: white">
					Particular
				</td>
				<td width="225" style="color: white">
					Serial No.
				</td>
				<td width="225" style="color: white">
					Brand
				</td>
			</tr> 
			<tr v-for="(item, index) in dataParams.delivery_receipt_item">
				<td width="225">
					<strong>{{item.asset_type}}</strong>
				</td>
				<td width="225">
					<strong>{{item.no}}</strong>
				</td>
				<td width="225">
					<strong>{{item.qty}}</strong>
				</td>
				<td width="225">
					<strong>{{item.unit}}</strong>
				</td>
				<td width="225">
					<strong>{{item.particular}}</strong>
				</td>
				<td width="225">
					<strong>{{item.serial_no}}</strong>
				</td>
				<td width="225">
					<strong>{{item.brand}}</strong>
				</td>
			</tr> 
		</table>
		<br>
			<div >
				Remarks: <strong>{{dataParams.remarks? dataParams.remarks : "N/A"}}</strong>
			</div>
		<br/>
		<hr/>
		<!-- <br/> -->
		<h4>References</h4>
		<br>
		<table>
			<tr>
				<td width="350">
					Released By: <strong>{{dataParams.released_by ? dataParams.released_by : "N/A"}}</strong>
				</td>
				<td width="350">
					Checked By: <strong>{{dataParams.checked_by ? dataParams.checked_by : "N/A"}}</strong>
				</td>
			</tr> 
		</table> 
		<br><br><br>

		<table>
			<tr>
				<td width="350">
					Received By: <strong>{{dataParams.received_by ? dataParams.received_by : "N/A"}}</strong>
				</td>
				<td width="350">
					Delivered By: <strong>{{dataParams.delivered_by ? dataParams.delivered.name : "N/A"}}</strong>
				</td>
			</tr> 
		</table>

	</div>
</template>
<script>
	import moment from 'moment'; 
	export default {
		mounted(){   

		},
		data(){
			return{
				moment
			}
		},
		props: {
			dataParams: {
				booking_no: "",
				delivery_date: "",
				address: "",
				contact_person: "",
				department: "",
				released_by: "",
				remarks: "",
				checked_by: "",
				received_by: "",
				delivered_by: "",
				dr_id: "",
				delivery_receipt_item: [
					{
						no: "",
						qty: "",
						unit: "units",
						particular: ""
					}
				],
				booking: {
					booking_no: ""
				}
			},
		},
	}
</script>