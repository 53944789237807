var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"p-4"},[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('h5',[_vm._v("Delivery Receipt /"+_vm._s(_vm.dataParams.booking.booking_no)+"/ "+_vm._s(_vm.dataParams.dr_id))])]),_c('CCol',{attrs:{"lg":"6"}},[_c('div',{staticStyle:{"text-align":"right"}},[_c('CButton',{staticClass:"px-3",attrs:{"size":"sm","color":"btn btn-info"},on:{"click":_vm.print}},[_c('i',{staticClass:"fa fa-print"}),_vm._v(" Print")]),_vm._v("   "),_c('CButton',{staticClass:"px-3",attrs:{"size":"sm","color":"btn btn-outline-danger"},on:{"click":function($event){return _vm.backToTable()}}},[_c('i',{staticClass:"fa fa-close"}),_vm._v("Close")]),_vm._v("   ")],1)])],1)],1),_c('CCardBody',[_c('fieldset',{staticClass:"fieldset full-line"},[_c('legend',[_vm._v(" DR Information")]),_c('CRow',[_c('CCol',{attrs:{"lg":"4"}},[_vm._v(" Customer: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.customer ? _vm.dataParams.customer.customer_name : "N/A"))])]),_c('CCol',{attrs:{"lg":"4"}},[_vm._v(" Delivery Date: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.delivery_date ? _vm.moment(new Date (_vm.dataParams.delivery_date*1000)).format('MMMM DD, YYYY') : "N/A"))])]),_c('CCol',{attrs:{"lg":"4"}},[_vm._v(" Department: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.department ? _vm.dataParams.department : "N/A"))])])],1),_c('CRow',[_c('CCol',{attrs:{"lg":"4"}},[_vm._v(" Address: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.address ? _vm.dataParams.address : "N/A"))])]),_c('CCol',{attrs:{"lg":"4"}},[_vm._v(" Contact Person: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.contact_person ? _vm.dataParams.contact_person : "N/A"))])])],1)],1),_c('fieldset',{staticClass:"fieldset full-line"},[_c('legend',[_vm._v(" DR Items")]),_c('CRow',{staticClass:"mb-1"},[_c('CCol',{attrs:{"lg":"12"}},[_c('CDataTable',{attrs:{"items":_vm.dataParams.delivery_receipt_item,"fields":[
								 {
									key: 'asset_type_view', 
									label: 'Asset Type'
								},
								{
									key: 'no', 
									label: 'Asset No.'
								},
								{
									key: 'qty', 
									label: 'Qty'
								},
								{
									key: 'unit', 
									label: 'Unit'
								},
								{
									key: 'particular', 
									label: 'Particular'
								},
								{
									key: 'serial_no_view', 
									label: 'Serial No.'
								},
								{
									key: 'brand_view', 
									label: 'Brand'
								}
							],"hover":"","striped":"","border":"","small":"","fixed":""}}),_c('CCol',{attrs:{"lg":"12"}},[_vm._v(" Remarks: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.remarks ? _vm.dataParams.remarks : "N/A"))])])],1)],1)],1),_c('fieldset',{staticClass:"fieldset full-line"},[_c('legend',[_vm._v(" References")]),_c('CRow',{staticClass:"ml-3 mr-3 mt-2"},[_c('CCol',{attrs:{"lg":"3"}},[_vm._v(" Released By: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.released_by ? _vm.dataParams.released_by : "N/A"))])]),_c('CCol',{attrs:{"lg":"3"}},[_vm._v(" Checked By: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.checked_by ? _vm.dataParams.checked_by : "N/A"))])]),_c('CCol',{attrs:{"lg":"3"}},[_vm._v(" Received By: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.received_by ? _vm.dataParams.received_by : "N/A"))])]),_c('CCol',{attrs:{"lg":"3"}},[_vm._v(" Delivered By: "),_c('strong',[_vm._v(_vm._s(_vm.dataParams.delivered_by ? _vm.dataParams.delivered.name : "N/A"))])])],1)],1)])],1),_c('Print',{attrs:{"dataParams":_vm.dataParams}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }